import * as yup from 'yup';
import { RegExEnum } from '@/app/utils/constants';
import { stripPhoneFormatting } from './helpers';

export function registerValidators() {
  yup.addMethod(yup.string, 'spacesValidator', function (errorMessage = 'Field cannot be empty') {
    return this.test(`spaces-validator`, errorMessage, function (value, ctx) {
      const { path, createError } = this;
      const inputValue = ctx.originalValue;
      const isSpaces = RegExEnum.SPACE.test(inputValue);
      if (isSpaces) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  });
  yup.addMethod(
    yup.string,
    'strongEmailValidator',
    function (errorMessage = 'Please enter a valid email address.') {
      return this.matches(RegExEnum.EMAIL, errorMessage);
    },
  );
  yup.addMethod(
    yup.string,
    'phoneValidator',
    function (
      firstPhoneName: string,
      secondPhoneName: string,
      incorrectPhoneFormatMsg: string,
      phoneRequiredMsg: string,
    ) {
      return this.transform((value: string, originalValue: string) => {
        return stripPhoneFormatting(value);
      }).when([firstPhoneName, secondPhoneName], {
        // Check if the other fields are empty
        is: (firstPhone: string, secondPhone: string) => !firstPhone && !secondPhone,
        // Required if we don't have any other phone numbers
        then: (schema) =>
          schema
            .required(phoneRequiredMsg)
            .max(10)
            .matches(RegExEnum.PHONE, incorrectPhoneFormatMsg),
        // Not required if we have another phone number
        otherwise: (schema) =>
          yup.lazy((value) =>
            !!value && value.length > 0
              ? schema.max(10).matches(RegExEnum.PHONE, incorrectPhoneFormatMsg)
              : schema.nullable().notRequired(),
          ),
      });
    },
  );
}
